@import 'pages/variables.css';

$linkColor: $colorBlue;
$linkHoverColor: $colorHover;
$linkVisitedColor: 85, 26, 139;

.root {
  font-size: 16px;
  line-height: 24px;
}

.root h1 {
  font-size: 28px;
  line-height: 32px;
}

.root h2,
.root h3 {
  line-height: 24px;
  margin: 8px 0;
}


.root h2 {
  font-size: 20px;
}

.root h3 {
  font-size: 18px;
}

.root p + h2,
.root p + h3,
.root ul + h2,
.root ul + h3,
.root ol + h2,
.root ol + h3 {
  margin: 40px 0 0;
}

.root p {
  margin: 8px 0;
}

.root img {
  display: block;
  width: 100%;
}

.root a {
  color: rgb($linkColor);
  transition: color 0.07s ease-out, border-color 0.1s ease-out;
}

.root a:visited {
  color: rgb($linkVisitedColor);
}

.root a:hover,
.root a:visited:hover {
  color: rgb($linkHoverColor);
  border-color: rgba($linkVisitedColor, 0.3);
}

.root li {
  margin: 8px 0;
}

.root ol {
  counter-reset: section;
  list-style-type: none;
}

.root ol li::before {
  counter-increment: section;
  content: counters(section, ". ") ". ";
}

.root ul li::before {
  content: "\2014\00A0";
}

.root blockquote {
  margin: 12px 0;
}

@media all and (min-width: $breakpointSmall) {
  .root h1 {
    font-size: 36px;
    line-height: 41px;
  }
}
