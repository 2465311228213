@import 'pages/variables.css';

@svg-load right-arrow url('components/common/icons/right-arrow.svg') {
  path {
    fill: rgb($colorGray60);
  }
}

.root {
  font-size: 16px;
  line-height: 24px;
  margin: 4px 0;

  &:not(:last-child) {
    background-image: svg-inline(right-arrow);
    background-repeat: no-repeat;
    background-position: center right;
    padding-right: 21px;
    margin-right: 5px;
  }
}
