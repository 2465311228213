.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

.info.app {
  padding: 16px 0;
}

.infoWrapper {
  margin: 0 0 48px;
}

.content {
  max-width: 910px;
  margin: 0 0 24px;
}
